.a-color-swatch {
	--size: var(--gap-300);
	--outline-size: 0.2rem;

	&[data-size="small"] {
		--size: var(--gap-250);
		--outline-size: 0.15rem;
	}

	display: block;
	block-size: var(--size);
	inline-size: var(--size);
	border-radius: 50%;
	box-shadow: 0 0 0.1rem var(--color-black);
	background-color: var(--color);
	background-image: var(--image);

	&[aria-pressed] {
		outline: var(--outline-size) solid var(--color-focus);
		outline-offset: var(--outline-size);
	}

	@media (max-width: calc(480 / 16 * 1em)) {
		--size: var(--gap-250);
		--outline-size: 0.15rem;
	}
}
