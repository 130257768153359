.a-button {
	--size: 4rem;
	--color-content: var(--color-content-primary-inverse);
	--color-background: var(--color-background-primary-inverse);
	display: inline-flex;
	padding-inline: var(--gap-300);
	padding-block: var(--gap-200);
	font: var(--font-label-medium);
	align-items: center;
	justify-content: center;
	border-radius: var(--border-radius-300);
	color: var(--color-content);
	background-color: var(--color-background);
	text-decoration: none;
	transition-property: background-color;
	transition-duration: 400ms;

	&[data-size="small"] {
		--size: 3rem;
	}
	&[data-size="xsmall"] {
		--size: 1.5rem;
	}

	&[data-kind="text"] {
		--color-content: var(--color-content-primary);
		margin-inline: calc(var(--gap-300) * -1);
		padding-block: 0;
		background-color: transparent;
		text-decoration: underline;
		text-decoration-color: color-mix(in hsl, var(--color-content-primary) 10%, transparent);
		transition-property: text-decoration-color;
		transition-duration: 100ms;
	}

	&[data-kind="circle"] {
		box-sizing: border-box;
		border-radius: 50%;
		padding: 0;
		width: var(--size);
		height: var(--size);
	}

	&[data-variant="secondary"] {
		--color-background: var(--color-gray-100);
		--color-content: var(--color-content-secondary);
		border: 1px solid var(--color-border-primary);
	}

	&[data-variant="tertiary"] {
		--color-background: transparent;
		--color-content: var(--color-content-primary);
	}

	&[data-variant="outline"] {
		--color-content: var(--color-content-primary);
		color: var(--color-content);
		border: 2px solid currentcolor;
		background-color: transparent;
	}

	&[data-stretch] {
		display: flex;
		box-sizing: border-box;
		width: 100%;
	}

	&[disabled] {
		--color-content: var(--color-background-tertiary);

		&[data-variant="outline"] {
			--color-content: var(--color-content-tertiary);
		}
	}

	> svg * {
		fill: currentcolor;
	}

	&:where(:focus-visible, :hover):not([disabled]) {
		background-color: color-mix(in hsl, var(--color-background), var(--color-content) 15%);
		transition-duration: 100ms;
		transition-timing-function: cubic-bezier(0, 0, 1, 1);

		&[data-variant="outline"] {
			background-color: color-mix(in hsl, transparent, var(--color-content) 5%);
		}
		&[data-variant="tertiary"] {
			background-color: color-mix(in hsl, transparent, var(--color-content) 5%);
		}

		&[data-kind="text"] {
			text-decoration-color: var(--color-content-primary);
			background-color: inherit;
		}
	}
	&:where(:active):not([disabled]) {
		transition-duration: 50ms;
		transition-timing-function: cubic-bezier(0, 0, 1, 1);

		&[data-variant="outline"] {
			background-color: color-mix(in hsl, transparent, var(--color-black) 10%);
		}
	}
}
