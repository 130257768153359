.m-table {
	--gap-block: var(--gap-200);
	--gap-inline: var(--gap-200);

	width: 100%;
	font: var(--font-label-medium);

	> tbody > tr:not(:first-child) > * {
		padding-block-start: var(--gap-block);
		border-block-start: .1rem solid currentcolor;
	}
	> tbody > tr:not(:last-child) > * {
		padding-block-end: var(--gap-block);
	}
	> tbody > tr > *:not(:first-child) {
		padding-inline-start: var(--gap-inline);
	}
	> tbody > tr > *:not(:last-child) {
		padding-inline-end: var(--gap-inline);
	}
	> tbody > tr > th {
		text-align: start;
		font-weight: 500;
		vertical-align: top;
	}
	> tbody > tr > td {
		font-weight: 700;
	}
}
