.t-default {
	&[data-theme="architecture"] {
		--color-content-primary: var(--color-brombeere);
		--color-content-secondary: color-mix(in oklab, var(--color-brombeere) 75%, var(--color-himbeere));
		--color-content-tertiary: color-mix(in oklab, var(--color-brombeere) 50%, var(--color-himbeere));
		--color-background-primary: var(--color-himbeere);
		--color-background-secondary: color-mix(in oklab, var(--color-himbeere) 75%, var(--color-brombeere));
		--color-background-tertiary: color-mix(in oklab, var(--color-himbeere) 50%, var(--color-brombeere));

		--color-content-primary-inverse: var(--color-himbeere);
		--color-background-primary-inverse: var(--color-brombeere);
		--color-background-secondary-inverse: color-mix(in oklab, var(--color-brombeere) 40%, var(--color-himbeere))
	}
}
