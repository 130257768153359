.o-header {
	box-sizing: border-box;
	margin-inline: auto;
	max-inline-size: var(--max-width-content-large);
	inline-size: 100%;
	padding-block-start: var(--gap-350);
	padding-inline: var(--gap-300);
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	align-items: end;
	column-gap: var(--gap-500);
	row-gap: var(--gap-200);
	color: var(--color-brombeere);

	@media (min-width: calc(800 / 16 * 1em)) {
		position: relative;
		z-index: 100;
		padding-inline: var(--gap-300);
	}
	@media (min-width: calc(1080 / 16 * 1em)) {
		padding-inline: var(--gap-500);
	}
}

.o-header__logo-link {
	display: block;
	margin-inline: calc(var(--gap-300) * -1);
	padding-block: var(--gap-200);
	padding-inline: var(--gap-300);

	@media (min-width: calc(800 / 16 * 1em)) {
		margin-block-end: 0.3rem;
	}
	@media not (min-width: calc(800 / 16 * 1em)) {
		position: relative;
		z-index: 101;
	}
}

.o-header__logo {
	height: 3rem;

	> svg {
		height: 100%;
		width: auto;
		pointer-events: none; /* Prevents tooltip */

		* {
			fill: currentcolor;
		}
	}
}
