/* Naming of colors and fonts inspired by Uber Base (https://base.uber.com/6d2425e9f/p/8898fb-color-legacy/b/065744) */

:root {
	/**
	 * 1rem equals to 10px.
	 * Each size should divide by 4.
	 */

	 /* Breakpoints */
	 /* Usage: (min-width: 600px). CSS variabels could not be used in media queries. */
	 --breakpoint-medium: 600px;
	 --breakpoint-large: 1080px;

	 /* Sizes */
	 --max-width-content-large: 126rem;
	 --max-width-content-medium: 78rem;
	 --max-width-text: 66rem;
	 --gap-50: 0.3rem;
	 --gap-100: 0.5rem;
	 --gap-200: 1rem;
	 --gap-250: 1.5rem;
	 --gap-300: 2rem;
	 --gap-350: 3rem;
	 --gap-400: 4rem;
	 --gap-450: 5rem;
	 --gap-500: 6rem;
	 --gap-550: 7rem;
	 --gap-600: 9rem;

	/* Typography */
	--font-family-karla: 'Karla', sans-serif;
	--font-family-newsreader: 'Newsreader', serif;

	--font-size-display-medium: 4.5rem;
	--font-size-heading-medium: 1.3rem;
	--font-size-label-large: 2.2rem;
	--font-size-label-medium: 1.7rem;
	--font-size-label-small: 1.5rem;
	--font-size-label-xsmall: 1.1rem;
	--font-size-paragraphs-large: 2.3rem;
	--font-size-paragraphs-medium: 1.9rem;
	--font-size-paragraphs-small: 1.5rem;
	--font-size-paragraphs-xsmall: 1.1rem;
	--font-size: var(--font-size-paragraphs-medium);

	--line-height-display-medium: calc(45 / 44);
	--line-height-heading-medium: calc(15 / 13);
	--line-height-label-large: calc(25 / 22);
	--line-height-label-medium: calc(20 / 17);
	--line-height-label-small: calc(15 / 15);
	--line-height-label-xsmall: calc(15 / 11);
	--line-height-paragraphs-large: calc(30 / 23);
	--line-height-paragraphs-medium: calc(25 / 19);
	--line-height-paragraphs-small: calc(20 / 15);
	--line-height-paragraphs-xsmall: calc(15 / 11);
	--line-height: var(--line-height-paragraphs-medium);

	--font-display-medium: 700 var(--font-size-display-medium) / var(--line-height-display-medium) var(--font-family-karla);
	--font-heading-medium: 800 var(--font-size-heading-medium) / var(--line-height-heading-medium) var(--font-family-karla);
	--font-label-large: 500 var(--font-size-label-large) / var(--line-height-label-large) var(--font-family-karla);
	--font-label-medium: 500 var(--font-size-label-medium) / var(--line-height-label-medium) var(--font-family-karla);
	--font-label-small: 500 var(--font-size-label-small) / var(--line-height-label-small) var(--font-family-karla);
	--font-label-xsmall: 500 var(--font-size-label-xsmall) / var(--line-height-label-xsmall) var(--font-family-karla);
	--font-paragraphs-large: 400 var(--font-size-paragraphs-large) / var(--line-height-paragraphs-large) var(--font-family-newsreader);
	--font-paragraphs-medium: 400 var(--font-size-paragraphs-medium) / var(--line-height-paragraphs-medium) var(--font-family-newsreader);
	--font-paragraphs-small: 500 var(--font-size-paragraphs-small) / var(--line-height-paragraphs-small) var(--font-family-karla);
	--font-paragraphs-xsmall: 500 var(--font-size-paragraphs-xsmall) / var(--line-height-paragraphs-xsmall) var(--font-family-karla);

	--letter-spacing-display-medium: -0.02em;
	--letter-spacing-heading-medium: 0.06em;

	--text-transform-heading-medium: uppercase;

	/* Color Primitives */
	--color-white:    hsla(0, 0%, 100%, 1);
	--color-gray-1:   #F8F8F8;
	--color-gray-50:  hsla(0, 0%, 95%, 1);
	--color-gray-100: hsla(0, 0%, 90%, 1);
	--color-gray-200: hsla(0, 0%, 80%, 1);
	--color-gray-300: hsla(0, 0%, 70%, 1);
	--color-gray-400: hsla(0, 0%, 60%, 1);
	--color-gray-500: hsla(0, 0%, 50%, 1);
	--color-gray-600: hsla(0, 0%, 40%, 1);
	--color-gray-700: hsla(0, 0%, 30%, 1);
	--color-gray-800: hsla(0, 0%, 20%, 1);
	--color-gray-900: hsla(0, 0%, 10%, 1);
	--color-gray-950: hsla(0, 0%, 5%, 1);
	--color-black: hsla(0, 0%, 0%, 1);

	--color-red-50:  oklch(95% 0.1 23);
	--color-red-100: oklch(90% 0.15 23);
	--color-red-200: oklch(80% 0.15 23);
	--color-red-300: #EE7774;
	--color-red-400: oklch(60% 0.15 23);
	--color-red-500: oklch(50% 0.15 23);
	--color-red-600: oklch(40% 0.15 23);
	--color-red-700: oklch(30% 0.15 23);
	--color-red-800: oklch(20% 0.15 23);
	--color-red-900: oklch(10% 0.15 23);

	--color-yellow-50:  hsl(57, 100%, 95%));
	--color-yellow-100: hsl(57, 100%, 90%);
	--color-yellow-200: hsl(57, 100%, 80%);
	--color-yellow-300: hsl(57, 100%, 70%);
	--color-yellow-400: hsl(57, 100%, 60%);
	--color-yellow-500: #F2E500;
	--color-yellow-600: hsl(57, 100%, 40%);
	--color-yellow-700: hsl(57, 100%, 30%);
	--color-yellow-800: hsl(57, 100%, 20%);
	--color-yellow-900: hsl(57, 100%, 10%);

	--color-indigo-50:  oklch(95% 0.1 287);
	--color-indigo-100: oklch(90% 0.1 287);
	--color-indigo-200: oklch(80% 0.1 287);
	--color-indigo-300: oklch(70% 0.1 287);
	--color-indigo-400: oklch(60% 0.1 287);
	--color-indigo-500: oklch(50% 0.1 287);
	--color-indigo-600: oklch(40% 0.1 287);
	--color-indigo-700: oklch(30% 0.1 287);
	--color-indigo-800: #180445;
	--color-indigo-900: oklch(10% 0.1 287);

	--color-blue-50:  oklch(95% 0.1 229);
	--color-blue-100: oklch(90% 0.12 229);
	--color-blue-200: #5AC5F2;
	--color-blue-300: oklch(70% 0.12 229);
	--color-blue-400: oklch(60% 0.12 229);
	--color-blue-500: oklch(50% 0.12 229);
	--color-blue-600: oklch(40% 0.12 229);
	--color-blue-700: oklch(30% 0.12 229);
	--color-blue-800: oklch(20% 0.12 229);
	--color-blue-900: oklch(10% 0.12 229);

	--color-himbeere: var(--color-red-300);
	--color-zitrone: var(--color-yellow-500);
	--color-brombeere: var(--color-indigo-800);
	--color-himmelblau: var(--color-blue-200);
	--color-focus: #390DE1;
	--color-link: #E52825;

	/* Foundation Colors */
	--color-primary-a: var(--color-black);
	--color-primary-b: var(--color-gray-1);
	--color-negative: var(--color-red-400);
	--color-warning: var(--color-yellow-200);
	--color-positive: var(--color-green-400);

	/* Core Colors */
	--color-content-primary: var(--color-black);
	--color-content-secondary: var(--color-gray-600);
	--color-content-tertiary: var(--color-gray-500);
	--color-content-primary-inverse: var(--color-white);
	--color-content-secondary-inverse: var(--color-gray-300);

	--color-background-primary: var(--color-primary-b);
	--color-background-secondary: var(--color-white);
	--color-background-tertiary: var(--color-gray-50);
	--color-background-primary-inverse: var(--color-primary-a);
	--color-background-secondary-inverse: var(--color-gray-400);

	--color-border-primary: var(--color-gray-300);
	--color-border-secondary: var(--color-gray-200);
	--color-border-opaque: color-mix(in hsl, var(--color-indigo-500) 8%, transparent);

	/* Blur */
	--blur-300: 0.6rem;
	--blur-400: 1.0rem;

	/* Border */
	--border-radius-100: 0.2rem;
	--border-radius-200: 0.4rem;
	--border-radius-300: 0.6rem; /* default */
}
