a-zoom-picture {
	display: flex;
	justify-items: center;
	align-items: center;
	overflow: hidden;
	border-radius: var(--border-radius-300);
	background-color: var(--color-background-secondary-inverse);

	> img {
		inline-size: 100%;
		user-select: none;
	}

	&[role="button"] {
		cursor: zoom-in;

		&[zoomed] {
			cursor: zoom-out;
		}
	}

	&[zoomed] {
		> img {
			pointer-events: none;
		}
	}
}
