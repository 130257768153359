.o-product-content {
	display: grid;
	row-gap: var(--gap-500);
	grid-template-columns: minmax(10px, 1fr);

	@media (min-width: calc(1080 / 16 * 1em)) {
		position: relative;
		z-index: 1;
		grid-column: 1 / -1;
		grid-row: 1 / 1;
		column-gap: inherit;
		align-items: inherit;
		grid-template-columns: inherit;
		pointer-events: none;

		> * {
			grid-column-start: 1;
			pointer-events: auto;
		}
		> :where(.m-gallery, .m-teaser) {
			grid-column-end: -1;
		}
		> .m-text {
			padding-inline: var(--gap-500);
		}
	}
}
