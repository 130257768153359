.o-product-info {
	max-width: 36rem;

	> h1 {
		font: var(--font-display-medium);
	}

	@media (min-width: calc(1080 / 16 * 1em)) {
		order: 1;
		grid-column: 2 / -1;
		grid-row: 1 / 1;
		padding-block-start: var(--gap-300);

		&:not([data-variant="no-sticky"]) {
			position: sticky;
			inset-block-start: max(0px, calc(15vh - 100px));
		}
	}
}


.o-product-info__description {
	font: var(--font-paragraphs-large);
	font-style: italic;
}
