.o-home {
	max-width: var(--max-width-content-large);
	width: calc(100% - var(--gap-200) * 2);
	margin-inline: auto;
	padding-inline: var(--gap-200);
	padding-block-start: var(--gap-400);
	display: grid;
	row-gap: var(--gap-400);

	@media (min-width: calc(1080 / 16 * 1em)) {
		column-gap: var(--gap-600);
		row-gap: var(--gap-500);
		align-items: start;
		grid-template-columns: auto;

		> .m-text {
			padding-block: var(--gap-300);
			padding-inline: var(--gap-500);
		}
	}
}
