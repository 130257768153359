.m-text {
	max-width: var(--max-width-text);

	> * + * {
		margin-block-start: var(--gap-200);
	}
	> :where(h1, h2, h3) + * {
		margin-block-start: var(--gap-300);
	}
	> * + :where(h1, h2, h3) {
		margin-block-start: var(--gap-500);
	}

	> :where(ul, ol, p) {
		font: var(--font-paragraphs-large);
	}

	> :where(ul, ol) {
		padding-inline-start: 1em;

		> li + li {
			margin-block-start: var(--gap-200);
		}
	}

	> * {
		strong {
			font-weight: 600;
		}
		a {
			/* Similar to .a-button[data-kind="text"] */
			color: var(--color-link);
			text-decoration-color: color-mix(in hsl, var(--color-content-primary) 10%, transparent);
			transition-property: text-decoration-color;
			transition-duration: 100ms;

			@supports (-webkit-hyphenate-limit-after: 15) {
				-webkit-hyphens: auto;
				-webkit-hyphenate-limit-after: 15;
				-webkit-hyphenate-limit-before: 15;
			}
			@supports (hyphenate-limit-chars: 30 15 15) {
				hyphens: auto;
				hyphenate-limit-chars: 30 15 15;
			}

			&:where(:focus-visible, :hover):not([disabled]) {
				text-decoration-color: currentcolor;
			}
		}
	}

	> :where(h1) {
		/* same as .a-h1 */
		font: var(--font-display-medium);
		letter-spacing: var(--letter-spacing-display-medium);
		margin-inline-start: -0.08em;
		text-wrap: balance;

		> strong {
			font-weight: inherit;
		}

		@supports (-webkit-hyphenate-limit-after: 8) {
			-webkit-hyphens: auto;
			-webkit-hyphenate-limit-after: 8;
			-webkit-hyphenate-limit-before: 8;
		}
		@supports (hyphenate-limit-chars: 16 8 8) {
			hyphens: auto;
			hyphenate-limit-chars: 16 8 8;
		}
	}
	> :where(h2, h3) {
		/* same as .a-h2 */
		font: var(--font-heading-medium);
		text-transform: var(--text-transform-heading-medium);
		letter-spacing: var(--letter-spacing-heading-medium);
		text-indent: -0.07em;
		text-wrap: balance;

		> strong {
			font-weight: inherit;
		}
	}
}
