m-modal {
	display: none;
	position: fixed;
	inset: 0;
	z-index: 10000;

	&[open] {
		display: grid;
	}
}

.m-modal__backdrop {
	display: flex;
	padding-block: var(--gap-300);
	padding-inline: var(--gap-100);
	background-color: color-mix(in hsl, var(--color-background-tertiary), transparent 50%);
	backdrop-filter: blur(6rem);
	overflow: auto;
}

.m-modal__content {
	position: relative;
	margin: auto;
	background-color: var(--color-background-secondary);
	padding-inline: var(--gap-600);
	padding-block: var(--gap-550);
	border-radius: var(--border-radius-300);
	border: 1px solid var(--color-border-secondary);

	@media (max-width: calc(480 / 16 * 1em)) {
		padding-block-start: var(--gap-600);
		padding-inline: var(--gap-300);
	}
}

.m-modal__button-close {
	position: absolute;
	inset-block-start: var(--gap-300);
	inset-inline-end: var(--gap-300);
}
