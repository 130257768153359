.m-teaser {
	background-color: var(--color-yellow-100);
	text-decoration: none;
	border-radius: var(--border-radius-300);
	overflow: hidden;
	box-shadow: inset 0 0 0 0.1rem color-mix(in lch, var(--color-yellow-500) 30%, transparent);

	@media (min-width: calc(630 / 16 * 1em)) {
		display: grid;
		grid-template-columns: 1fr 1fr;
	}
}
a.m-teaser {
	&:where(:focus-visible, :hover) {
		.a-button[data-kind="text"] {
			text-decoration-color: var(--color-content-primary);
		}
	}
}

.m-text__image {
	align-self: stretch;
	object-fit: cover;
}

.m-teaser__text {
	padding-block: var(--gap-300);
	padding-inline: var(--gap-300);
	align-self: center;

	> * + * {
		margin-block-start: var(--gap-200);
	}

	@media (min-width: calc(630 / 16 * 1em)) {
		padding-inline: var(--gap-350);
	}
	@media (min-width: calc(800 / 16 * 1em)) {
		padding-inline: var(--gap-400);
	}
	@media (min-width: calc(1080 / 16 * 1em)) {
		padding-inline: var(--gap-600);
	}
}
