.a-pagination {
	> ul {
		display: flex;
		list-style: none;
		column-gap: var(--gap-100);
	}

	&[data-kind="dots"] {
		--size: 2rem;

		> ul > li > button {
			--background-color: var(--color-background-secondary-inverse);
			display: block;
			width: var(--size);
			border-radius: 50%;
			aspect-ratio: 1;
			background-image: radial-gradient(circle at 50%, var(--background-color) 50%, transparent 55%);
			background-size: 1rem 1rem;
			background-position: center;
			background-repeat: no-repeat;
			transition-property: background-image;
			transition-duration: 400ms;

			&:where(:focus-visible, :hover):not([disabled]) {
				--background-color: color-mix(in oklab, var(--color-background-secondary-inverse), var(--color-background-primary-inverse) 25%);
			}

			&[aria-pressed] {
				--background-color: var(--color-background-primary-inverse);
				transition-duration: 50ms;
				transition-timing-function: cubic-bezier(0, 0, 1, 1);
			}
		}
	}
}
