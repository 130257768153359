.o-product {
	max-width: var(--max-width-content-large);
	width: calc(100% - var(--gap-300) * 2);
	margin-inline: auto;
	display: grid;
	column-gap: var(--gap-600);
	row-gap: var(--gap-500);
	padding-inline: var(--gap-300);
	padding-block: var(--gap-500);
	grid-template-columns: minmax(10px, 1fr);

	@media (min-width: calc(1080 / 16 * 1em)) {
		align-items: start;
		grid-template-columns: 75fr 36fr;
	}
}
