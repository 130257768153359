.m-gallery-color {
	display: grid;

	> * {
		grid-column: 1 / 2;
		grid-row: 1 / 2;
	}

	&[data-ratio="3/2"] {
		aspect-ratio: 3/2;
	}
	&[data-ratio="1/1"] {
		aspect-ratio: 1/1;
	}
}


.m-gallery-color__navigation {
	justify-self: end;
	align-self: start;
	z-index: 1;
	display: flex;
	column-gap: var(--gap-300);
	row-gap: var(--gap-200);
	align-items: center;
	justify-items: end;
	flex-wrap: wrap;
	padding: var(--gap-300);

	> ul {
		list-style: none;
		display: flex;
		gap: var(--gap-200);
	}

	@media (max-width: calc(480 / 16 * 1em)) {
		display: grid;
		padding: var(--gap-200);
	}
}


.m-gallery-color__color-label {
	font: var(--font-label-medium);
	font-weight: 700;
	color: var(--color);

	@media (max-width: calc(480 / 16 * 1em)) {
		font: var(--font-label-small);
		font-weight: 700;
		order: 1;
	}
}


.m-gallery-color__images {
	> ul {
		display: grid;
		list-style: none;

		> li {
			grid-column: 1 / 2;
			grid-row: 1 / 2;
			transition-property: opacity;
			transition-duration: 400ms;
			transition-timing-function: linear;

			&[inert] {
				z-index: -1;
				opacity: 0;
			}
		}
	}
}
