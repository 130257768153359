.a-button-toggle-nav {
	display: block;
	margin-inline: calc(var(--gap-300) * -1);
	padding-block: var(--gap-200);
	padding-inline: var(--gap-300);
	font: var(--font-label-medium);
	transition-property: color;
	transition-duration: 100ms;

	&[aria-expanded="true"] {
		color: color-mix(in hsl, currentcolor, transparent);
	}

	@media (min-width: calc(800 / 16 * 1em)) {
		display: none;
	}
	@media not (min-width: calc(800 / 16 * 1em)) {
		position: relative;
		z-index: 101;
	}
}
