.o-product-teaser {
	--column-product-image: 36rem;
	display: grid;
	grid-template-columns: 1fr 1fr;
	align-items: end;
	text-decoration: none;
	pointer-events: none;
	color: var(--color-brombeere);

	> * {
		pointer-events: auto;
	}

	@media not (min-width: calc(1080 / 16 * 1em)) {
		max-width: 84rem;
		margin-inline: auto;
		row-gap: var(--gap-200);
		column-gap: var(--gap-300);
	}
	@media (min-width: calc(1080 / 16 * 1em)) {
		grid-template-columns: 1fr var(--column-product-image);
		column-gap: var(--gap-500);

		&[data-alignment="end"] {
			grid-template-columns: var(--column-product-image) 1fr;
		}
	}
}

.o-product-teaser__teaser-image,
.o-product-teaser__product-image {
	/* overflow: hidden; */

	> img {
		border-radius: var(--border-radius-300);
		transition-property: filter, box-shadow;
		transition-duration: 200ms;
		transition-timing-function: linear;
	}

	.o-product-teaser:where(:focus-visible, :hover) & {
		> img {
			filter: brightness(0.95);
			transition-duration: 400ms;
			transition-timing-function: ease-out;
		}
	}

	@media (min-width: calc(1080 / 16 * 1em)) {
		grid-row-start: 1;
		aspect-ratio: var(--aspect-ratio);
	}
}

.o-product-teaser__teaser-image {
	.o-product-teaser:where(:focus-visible, :hover) & {
		> img {
			transition-duration: 800ms;
			filter: brightness(0.9);
		}
	}

	@media not (min-width: calc(1080 / 16 * 1em)) {
		grid-column: 1 / -1;
	}
}

.o-product-teaser__product-image {
	@media not (min-width: calc(1080 / 16 * 1em)) {
		.o-product-teaser[data-alignment="end"] & {
			order: 2;
		}
	}
	@media (min-width: calc(1080 / 16 * 1em)) {
		.o-product-teaser[data-alignment="end"] & {
			order: -1;
		}
	}
}

.o-product-teaser__title {
	font: var(--font-label-medium);

	> h2 {
		font-size: inherit;
	}

	> p {
		color: var(--color-content-secondary);
	}

	.o-product-teaser:where(:focus-visible, :hover) & {
		> h2 {
			text-decoration: underline;
		}
	}

	@media not (min-width: calc(1080 / 16 * 1em)) {
		align-self: center;
		font: var(--font-label-large);

		.o-product-teaser[data-alignment="end"] & {
			text-align: end;
		}
	}
	@media (min-width: calc(1080 / 16 * 1em)) {
		padding-block-start: var(--gap-200);
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		column-gap: var(--gap-200);

		.o-product-teaser[data-alignment="start"] & {
			grid-column-start: 2;
		}
	}
}

