.a-figure {
	max-width: 79rem;

	> img {
		border-radius: var(--border-radius-300);
		inline-size: 100%;
	}


	@media (min-width: calc(600 / 16 * 1em)) {
		> figcaption {
			margin-block-start: var(--gap-250);
			padding-inline: var(--gap-500);
			font: var(--font-label-medium);
			color: var(--color-content-secondary);
		}
	}
}
