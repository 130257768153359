/**
 * could not use a `--gap` variable.
 * Otherwise it could conflict with .m-stack > .m-stack constructions.
 */
.m-stack {
	> * + * {
		margin-block-start: var(--gap-300);
	}

	&[data-size="medium"] {
		> * + * {
			margin-block-start: var(--gap-400);
		}
	}
	&[data-size="large"] {
		> * + * {
			margin-block-start: var(--gap-500);
		}
	}
	&[data-size="xlarge"] {
		> * + * {
			margin-block-start: var(--gap-600);
		}
	}
}
