.m-gallery {
	--max-inline-size: var(--max-width-content-large);

	max-width: var(--max-width-content-large);
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
}

.m-gallery__images {
	--gradient-size: calc(var(--gap-500));
	inline-size: 100vw;
	margin-block: calc(var(--gap-500) * -1);
	padding-block: var(--gap-500);
	background-color: color-mix(in hsl, var(--color-background-primary) 80%, transparent);
	backdrop-filter: blur(3rem);
	/* Cubic easing, see https://easings.net/#easeInOutCubic */
	mask-image: linear-gradient(
		transparent,
		color-mix(in hsl, black 33.0078125%, transparent),
		color-mix(in hsl, black 57.8125%, transparent),
		color-mix(in hsl, black 75.5859375%, transparent),
		color-mix(in hsl, black 87.5%, transparent),
		color-mix(in hsl, black 94.7265625%, transparent),
		color-mix(in hsl, black 98.4375%, transparent),
		color-mix(in hsl, black 99.8046875%, transparent),
		black var(--gradient-size),
		black calc(100% - var(--gradient-size) * 0.75),
		color-mix(in hsl, black 99.8046875%, transparent),
		color-mix(in hsl, black 98.4375%, transparent),
		color-mix(in hsl, black 94.7265625%, transparent),
		color-mix(in hsl, black 87.5%, transparent),
		color-mix(in hsl, black 75.5859375%, transparent),
		color-mix(in hsl, black 57.8125%, transparent),
		color-mix(in hsl, black 33.0078125%, transparent),
		transparent);

	> ul {
		flex-shrink: 0;
		display: flex;
		overflow: auto;
		list-style: none;
		scroll-behavior: smooth;
		scroll-snap-type: x mandatory;
		scrollbar-width: none;
		cursor: grab;

		&::-webkit-scrollbar {
			display: none;
		}

	}
	> ul > li {
		flex-shrink: 0;
		scroll-snap-align: center;

		> img {
			inline-size: 100%;
			border-radius: var(--border-radius-300);
		}
	}

	@media not (min-width: 1260px) {
		ul > li {
			inline-size: calc(100vw - var(--gap-200) * 4);
			padding-inline: var(--gap-100);

			&:first-child {
				padding-inline-start: calc(var(--gap-200) * 2);
			}
			&:last-child {
				padding-inline-end: calc(var(--gap-200) * 2);
			}
		}
	}
	@media (min-width: 1260px) {
		> ul {
			gap: var(--gap-400);
		}
		> ul > li {
			inline-size: var(--max-inline-size);

			&:first-child {
				padding-inline-start: calc((100vw - var(--max-inline-size)) * 0.5);
			}
			&:last-child {
				padding-inline-end: calc((100vw - var(--max-inline-size)) * 0.5);
			}
		}
	}
}

.m-gallery__navigation {
	position: relative;
	flex-basis: 100%;
	block-size: var(--gap-500);
	display: flex;
	padding-inline: var(--gap-100);
	justify-content: space-between;
	flex-wrap: wrap;
	align-items: center;

	@media (min-width: calc(1080 / 16 * 1em)) {
		padding-inline: var(--gap-500);
	}
}

.m-gallery__navigation-buttons {
	display: flex;
	gap: var(--gap-200);
}
