.m-main-nav {
	justify-self: end;

	> ul,
	> ul > li > ul {
		list-style: none;
	}
	> ul > li > ul {
		display: flex;
		gap: var(--gap-50);
	}

	@media (min-width: calc(800 / 16 * 1em)) {
		> ul {
			display: flex;
			margin-inline: calc(var(--gap-300) * -1);
			column-gap: var(--gap-200);
			row-gap: var(--gap-100);
			flex-wrap: wrap;
		}

		> ul > li > ul {
		}
	}

	@media (min-width: calc(1080 / 16 * 1em)) {
		> ul {
			column-gap: var(--gap-400);
		}
	}

	@media not (min-width: calc(800 / 16 * 1em)) {
		display: none;
		position: fixed;
		z-index: 100;
		inset: 0;
		background-color: color-mix(in hsl, var(--color-background-primary) 50%, transparent);
		backdrop-filter: blur(4rem);
		padding-block-end: var(--gap-300);
		padding-block-start: calc(8rem + var(--gap-300));
		padding-inline: var(--gap-100);
		text-align: end;
		overflow: auto;

		> ul {
			margin-inline-start: auto;
			max-width: 40rem;

			> li {
				margin-block-start: var(--gap-300);

			}
		}
		> ul > li > ul {
			flex-direction: column;
			align-items: end;
		}

		&[data-open] {
			display: block;
		}
	}
}

.m-main-nav__headline {
	display: block;
	margin-block-end: .2rem;
	padding-inline: var(--gap-300);
	font: var(--font-label-xsmall);
	text-transform: uppercase;
	letter-spacing: var(--leter-spacing-uppercase);
	color: var(--color-content-secondary);

	@media not (min-width: calc(800 / 16 * 1em)) {
		padding-inline: var(--gap-250);
		font: var(--font-label-small);
		margin-block-end: var(--gap-200);
	}
}

.m-main-nav__link {
	--border-width: .1rem;
	position: relative;
	display: block;
	text-decoration: none;

	> span {
		position: relative;
		z-index: 1;
		display: block;
		padding-block: calc(var(--gap-200) - var(--border-width));
		padding-inline: calc(var(--gap-300) - var(--border-width));
		font: var(--font-label-medium);
		border-width: var(--border-width);
		border-style: solid;
		border-color: transparent;
		border-radius: var(--border-radius-300);
		transition-property: border-color, border-radius, color, background-color;
		transition-duration: 400ms;
	}

	&[data-theme="architecture"] {
		&::before {
			--_size: 1rem;
			content: '';
			position: absolute;
			left: calc(var(--gap-200) - 0.5rem);
			top: calc(50% - var(--_size) * 0.5);
			width: var(--_size);
			height: var(--_size);
			background-color: var(--color-himbeere);
			border-radius: 50%;
			transition-duration: 400ms, 400ms;
			transition-property: scale, translate;
		}
	}

	&:where(:focus-visible, :hover) {
		z-index: 2;

		> span {
			border-color: var(--color-border-opaque);
			color: var(--color-link);
			background-color: color-mix(in hsl, var(--color-white) 80%, var(--color-background-primary));
			transition-duration: 100ms;
		}

		&[data-theme="architecture"] {
			&::before {
				translate: 0.2rem 0;
				scale: 0;
			}
		}

		body[data-theme="architecture"] & {
			> span {
				background-color: color-mix(in hsl, var(--color-white) 63%, var(--color-background-primary));
			}
			&[aria-current="page"] {
				> span {
					color: inherit;
					background-color: color-mix(in hsl, var(--color-white) 15%, var(--color-background-primary));
				}
			}
		}
	}
	&[aria-current="page"]:not(:where(:focus-visible, :hover)) {
		> span {
			border-color: var(--color-border-opaque);
			background-color: color-mix(in hsl, var(--color-white) 80%, var(--color-background-primary));
			transition-duration: 100ms;
		}

		body[data-theme="architecture"] & {
			> span {
				background-color: color-mix(in hsl, var(--color-white) 15%, var(--color-background-primary));
			}
		}
	}

	@media not (min-width: calc(800 / 16 * 1em)) {
		> span {
			font: var(--font-label-large);
		}
	}
	@media (min-width: calc(800 / 16 * 1em)) {
		&:where(:focus-visible, :hover) {
			&[data-variant="product"] {
				> span {
					border-block-end-width: 0;
					border-end-end-radius: 0;
					border-end-start-radius: 0;
				}
			}
		}
	}
}

.m-main-nav__image {
	@media not (min-width: calc(800 / 16 * 1em)) {
		display: none;
	}
	@media (min-width: calc(800 / 16 * 1em)) {
		position: absolute;
		inset-inline: 0;
		inset-block-start: calc(100% - var(--border-width));
		display: flex;
		justify-content: center;
		pointer-events: none;

		> span {
			position: relative;
			display: block;
			inline-size: 20rem;
			border-style: solid;
			border-color: var(--color-border-opaque);
			border-width: var(--border-width);
			border-radius: var(--border-radius-300);
			background-color: color-mix(in hsl, var(--color-white) 80%, var(--color-background-primary));
			backdrop-filter: blur(2rem);
			clip-path: inset(0 calc(50% - 2rem) 100%);
			transition-property: clip-path, background-color;
			transition-duration: 100ms;
			transition-timing-function: linear;
		}

		> span > svg {
			max-inline-size: none;
			inline-size: inherit;
			opacity: 0;
			transform-origin: center 90%;
			scale: 0.95;
			transition-property: opacity, scale;
			transition-duration: inherit;
			transition-timing-function: inherit;

			*:where([stroke="#000000"], [stroke="#000"]) {
				stroke: var(--color-link);
			}
		}


		body[data-theme="architecture"] & {
			> span {
				background-color: color-mix(in hsl, var(--color-white) 63%, var(--color-background-primary));
			}
		}

		.m-main-nav__link:where(:focus-visible, :hover) > & {
			> span {
				transition-duration: 200ms;
				transition-delay: 50ms;
				clip-path: inset(0);
				transition-timing-function: ease-out;

			}
			> span > svg {
				opacity: 1;
				scale: 1;
				transition-duration: 300ms;
				transition-delay: inherit;
			}
		}
	}
}
