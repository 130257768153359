.o-content {
	max-inline-size: var(--max-width-content-medium);
	inline-size: calc(100% - var(--gap-300) * 2);
	margin-inline: auto;
	padding-inline: var(--gap-300);
	padding-block: var(--gap-500);
	display: grid;
	grid-template-columns: minmax(10px, 1fr);

	&[data-size="large"] {
		max-inline-size: var(--max-width-content-large);
	}

	> * + * {
		margin-block-start: var(--gap-400);
	}
	> * + :where(.a-figure, .m-gallery, .o-product-teaser) {
		margin-block-start: var(--gap-500);
	}

	> .a-figure {
		width: 100%;
	}
	> .m-gallery {
		max-width: inherit;
		--max-inline-size: var(--max-width-content-medium);
	}

	@media (min-width: calc(600 / 16 * 1em)) {
		> .m-text {
			padding-inline: var(--gap-500);
			inline-size: calc(100% - var(--gap-500) * 2);
			margin-inline: auto;
		}
		> .a-h1 {
			padding-inline: var(--gap-500);
		}
	}
	@media (min-width: calc(1080 / 16 * 1em)) {
		padding-inline: var(--gap-500);
		inline-size: calc(100% - var(--gap-500) * 2);
		> .m-text {
			margin-inline: 0;
		}
	}
}
