.o-footer {
	margin-block-start: auto;
	color: var(--color-brombeere);
	padding-block-start: var(--gap-350);
	padding-block-end: var(--gap-300);
	border-block-start: .1rem solid currentcolor;

	> div {
		box-sizing: border-box;
		max-inline-size: var(--max-width-content-large);
		margin-inline: auto;
		padding-inline: var(--gap-300);
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: end;
		column-gap: var(--gap-500);
		row-gap: var(--gap-200);
	}

	@media (min-width: calc(1080 / 16 * 1em)) {
		> div {
			padding-inline: var(--gap-500);
		}
	}
}

.o-footer__logo-link {
	margin-inline: calc(var(--gap-200) * -1);
	padding-block: var(--gap-100);
	padding-inline: var(--gap-200);
}

.o-footer__logo {
	height: 2rem;

	> svg {
		height: 100%;
		width: auto;
		pointer-events: none; /* Prevents tooltip */

		* {
			fill: currentcolor;
		}
	}
}

.o-footer__nav {
	justify-self: end;
	margin-block-end: -0.3rem;
	margin-inline: calc(var(--gap-200) * -1);

	> ul {
		list-style: none;
		display: flex;
		flex-wrap: wrap;
		font: var(--font-label-small);
		font-weight: 500;
		column-gap: var(--gap-200);
	}
}

.o-footer__nav-link {
	display: block;
	padding-block: var(--gap-100);
	padding-inline: var(--gap-200);
	text-decoration: none;

	&:where(:focus-visible, :hover) {
		text-decoration: underline;
	}
}
