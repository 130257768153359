m-surface-swatches {
}

.m-surface-swatches__label {
	color: var(--color);
}

.m-surface-swatches__navigation {
	> ul {
		display: flex;
		margin-block-start: var(--gap-100);
		gap: var(--gap-200);
		list-style: none;
	}
}
