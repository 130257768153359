.a-h1 {
	font: var(--font-display-medium);
	letter-spacing: var(--letter-spacing-display-medium);
	margin-inline-start: -0.08em;
	text-wrap: balance;

	@supports (-webkit-hyphenate-limit-after: 8) {
		-webkit-hyphens: auto;
		-webkit-hyphenate-limit-after: 8;
		-webkit-hyphenate-limit-before: 8;
	}
	@supports (hyphenate-limit-chars: 16 8 8) {
		hyphens: auto;
		hyphenate-limit-chars: 16 8 8;
	}
}
.a-h2 {
	font: var(--font-heading-medium);
	text-transform: uppercase;
	letter-spacing: var(--letter-spacing-heading-medium);
	margin-inline-start: -0.07em;
	text-wrap: balance;
}
